import {
  type TeaserOrHtmlBlock,
  isAdditionalTeaserContent,
  isHtmlTeaserFragment,
  isNewsletterTeaserFragment,
  isRealTeaserContent,
  isRssArticleListFragment,
} from '@hubcms/domain-cook';

import type { SingleTeaserFragment } from '../domain/single-teaser-fragment';

export function isSingleTeaserFragment(teaserFragment: TeaserOrHtmlBlock): teaserFragment is SingleTeaserFragment {
  if (!teaserFragment) {
    return false;
  }
  return (
    isHtmlTeaserFragment(teaserFragment) ||
    isRssArticleListFragment(teaserFragment) ||
    isNewsletterTeaserFragment(teaserFragment) ||
    isRealTeaserContent(teaserFragment.content) ||
    isAdditionalTeaserContent(teaserFragment.content)
  );
}
