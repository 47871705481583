import type { TeaserFragment } from '@hubcms/domain-cook';
import type { TeaserDataOrList, TeaserPropsOptions } from '@hubcms/domain-teaser';
import { isNonNull } from '@hubcms/utils-browser';

import { isSingleTeaserFragment } from './isSingleTeaserFragment';
import { mapTeaserData } from './mapTeaserData';
import { mapTeaserList } from './mapTeaserList';

export function mapTeaserFragment(
  teaserFragment: TeaserFragment,
  teaserPropsOptions: TeaserPropsOptions,
): TeaserDataOrList | null {
  if (!isSingleTeaserFragment(teaserFragment)) {
    return mapTeaserList(teaserFragment, teaserPropsOptions);
  }
  return mapTeaserData(teaserFragment, teaserPropsOptions);
}

export function mapTeaserFragments(
  teaserFragments: TeaserFragment[],
  teaserPropsOptions: TeaserPropsOptions,
): TeaserDataOrList[] {
  return (teaserFragments || []).map(teaserFragment => mapTeaserFragment(teaserFragment, teaserPropsOptions)).filter(isNonNull);
}
